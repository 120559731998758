<template>
  <div>
    <v-list flat subheader dense class="py-0">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="d-flex flex-column align-left">
            <v-row
              no-gutters
              align="center"
              justify="start"
              class="default--text text-body-2"
              :class="{ 'mb-2': isCart }"
            >
              <v-icon small color="secondary" v-if="isCart || isFastPayment">{{
                serviceIcon
              }}</v-icon>
              <span
                v-if="isCart"
                class="ml-2 secondary--text"
                style="line-height:1"
                >{{ $t("cart.cartInfo.serviceSelected") }}</span
              >
              <v-btn
                class="cart-info-button text-none font-weight-bold secondary--text no-border"
                :class="isCart ? 'text-body-2' : 'text-caption'"
                :disabled="!editableAddress || isFastPayment"
                text
                tile
                small
                elevation="0"
                rounded
                @click="openAddressSelector"
              >
                <span>{{ serviceName }} </span>
                <v-icon small right v-if="!isCart && !isFastPayment"
                  >$edit</v-icon
                >
              </v-btn>
              <!-- icon to be shown in cart drawer -->
              <v-icon
                small
                v-if="isCart"
                color="default"
                class="cart-edit-icon"
                @click="openAddressSelector"
                >$edit</v-icon
              >
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="start"
              class="default--text text-body-2"
              :class="{ 'mb-2': isCart }"
            >
              <v-icon small color="secondary" v-if="isCart || isFastPayment"
                >$storefront</v-icon
              >
              <span
                v-if="isCart"
                class="ml-2 secondary--text"
                style="line-height:1"
                >{{ $t("cart.cartInfo.editableAddress") }}</span
              >
              <v-btn
                class="cart-info-button text-none font-weight-bold secondary--text no-border"
                :class="isCart ? 'text-body-2' : 'text-caption'"
                :disabled="!editableAddress || isFastPayment"
                text
                tile
                small
                elevation="0"
                rounded
                @click="openAddressSelector"
              >
                <span>{{ shippingAddress }} </span>
                <v-icon small right v-if="!isCart && !isFastPayment"
                  >$edit</v-icon
                >
              </v-btn>
              <!-- icon to be shown in cart drawer -->
              <v-icon
                small
                v-if="isCart"
                color="default"
                class="cart-edit-icon"
                @click="openAddressSelector"
                >$edit</v-icon
              >
            </v-row>
            <v-row
              no-gutters
              align="center"
              class="mt-0 default--text text-body-2"
            >
              <v-icon small color="secondary" v-if="isCart || isFastPayment"
                >$editcalendar</v-icon
              >
              <span
                v-if="isCart"
                class="ml-2 secondary--text"
                style="line-height:1"
                >{{ $t("cart.cartInfo.timeslot") }}</span
              >
              <v-btn
                class="cart-info-button text-none font-weight-bold secondary--text no-border"
                :class="isCart ? 'text-body-2' : 'text-caption'"
                :disabled="!editableTimeslot || isFastPayment"
                text
                tile
                small
                elevation="0"
                rounded
                @click="openTimeslotSelector"
              >
                <span class="text-none">{{ timeslot }}</span>
                <v-icon small right v-if="!isCart && !isFastPayment"
                  >$edit</v-icon
                ></v-btn
              >
              <!-- icon to be shown in cart drawer -->
              <v-icon
                small
                v-if="isCart"
                color="default"
                class="cart-edit-icon"
                @click="openTimeslotSelector"
                >$edit</v-icon
              >
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div
      class="d-flex justify-end align-center font-weight-bold secondary white--text text-caption mt-2 pr-5"
      style="height: 25px"
    />
  </div>
</template>
<style lang="scss" scoped>
.cart-info-button {
  justify-content: left;
  font-size: 12px;
  height: 20px !important;
  letter-spacing: normal;
  &.v-btn--disabled {
    color: $secondary !important;
  }
  .v-btn__content {
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-icon {
    font-size: $font-size-root;
    height: 14px;
    width: 14px;
  }
}
.cart-edit-icon {
  position: absolute;
  right: 20px;
}
.cart-info-label {
  max-width: 212px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 1px 0;
}
</style>
<script>
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import get from "lodash/get";
import { mapState } from "vuex";
export default {
  name: "CartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  props: {
    isCart: { type: Boolean, required: false },
    isFastPayment: { type: Boolean, default: false }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      let timeslot = get(this.cart, "timeslot");
      let forcedPreviewDate = get(this.cart, "forcedPreviewDate");

      if (!timeslot || timeslot.autoAssigned) {
        return this.$t("navbar.noTimeslot");
      } else {
        let dateFormat = this.$te("navbar.timeslot.dateFormat")
          ? this.$t("navbar.timeslot.dateFormat")
          : "DD MMMM";
        var date;
        if (this.$dayjs) {
          date = this.$dayjs(timeslot.date).format(dateFormat);
        } else {
          date = this.$te("navbar.timeslot.dateFormat")
            ? this.$DateTime
                .fromISO(timeslot.date)
                .toFormat(this.$t("navbar.timeslot.dateFormat"))
            : this.$DateTime.fromISO(timeslot.date).toFormat("dd MMMM");
        }

        if (this.$te("navbar.timeslot.format")) {
          return this.$t("navbar.timeslot.format", {
            date: date,
            beginTime: timeslot.beginTime,
            endTime: timeslot.endTime,
            name: timeslot.name
          });
        }

        if (forcedPreviewDate) {
          var forcedPreviewDateFormatted = "";
          if (this.$dayjs) {
            forcedPreviewDateFormatted = this.$dayjs(forcedPreviewDate).format(
              dateFormat
            );
          } else {
            forcedPreviewDateFormatted = this.$te("navbar.timeslot.dateFormat")
              ? this.$DateTime
                  .fromISO(forcedPreviewDate)
                  .toFormat(this.$t("navbar.timeslot.dateFormat"))
              : this.$DateTime.fromISO(forcedPreviewDate).toFormat("dd MMMM");
          }
          return (
            date + " (" + forcedPreviewDateFormatted + ") | " + timeslot.name
          );
        } else {
          return date + " | " + timeslot.name;
        }
      }
    },
    serviceIcon() {
      let shippingAddress = get(this.cart, "shippingAddress");
      if (shippingAddress && shippingAddress.deliveryServiceId) {
        return (
          "$" +
          this.$t(
            "navbar.service." + shippingAddress.deliveryServiceId + ".fontIcon"
          )
        );
      } else {
        return "";
      }
    },
    minimumPrice() {
      let shippingAddress = get(this.cart, "shippingAddress");
      let deliveryFee = get(this.cart, "deliveryFee");
      if (shippingAddress && shippingAddress.deliveryServiceId) {
        let minPrice = this.$t(
          "navbar.service." +
            shippingAddress.deliveryServiceId +
            ".minimumPrice"
        );
        return shippingAddress.deliveryServiceId == 2
          ? minPrice + ", Spese di consegna " + this.$n(deliveryFee)
          : minPrice;
      }

      return "";
    }
  }
};
</script>
